import { QuestionMarkCircleIcon, UserIcon, ArrowLeftStartOnRectangleIcon, Cog6ToothIcon} from '@heroicons/react/24/solid';
import { Sidebar, Menu, MenuItem} from 'react-pro-sidebar';
import React, { useMemo } from 'react';
import { Button, Image, Link, useDisclosure } from '@nextui-org/react';
import { HomeIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import MessageInformations from './Utils/MessageInformations';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { confirmAlert } from 'react-confirm-alert';


function SideLeftMenu (props) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [backgroundColor, setBackgroundColor] = React.useState("transparent");
  const hoverLogout = () => {
    setBackgroundColor(backgroundColor === "transparent" ? "#fdd0df" : "transparent");
  }
  const clientData = useMemo(() => {
    var client = localStorage.getItem('client');
    return JSON.parse(client ? client : "{}");
  }, [localStorage.getItem('client')]);

    const companyLogo = useMemo(() => {
      return clientData.logo_principal
    }, [localStorage.getItem('client')]);

    const logout = () => {
      confirmAlert({
        message: 'Voulez-vous vraiment vous déconnecter ?',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
              axios.post(process.env.REACT_APP_BASE_URL_API + "/users/logout", {}, { withCredentials: true }).then((response) => {
                props.setLogged(false);
                localStorage.clear();
              }).catch(() => {
                  confirmAlert({
                    title: 'Erreur de déconnexion',
                    message: 'Veuillez réessayer.',
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => {}
                      }
                    ]
                  });
              });
            }
          },
          {
            label: 'Non',
            onClick: () => {}
          }
        ]})
  }
    return (
        <Sidebar
          collapsed={!props.trigger}
          toggled={props.trigger}
          breakPoint="md"
          onBackdropClick={() => props.setMenuTrigger(false)}
          className='flex flex-col'
          width={'240px'}
        >
          
          <div className='flex flex-col h-screen'>
            { props.trigger ?
              <div className='flex flex-row items-center justify-center w-full p-4 overflow-hidden'>
                <Image loading="true" src={ companyLogo ? companyLogo : require('../avatar.png')} alt="avatar" className="w-[150px] h-[150px]"/>
              </div>
              : <div className='flex flex-row items-center justify-center w-full p-4 overflow-hidden'>
                  <Image loading="true" src={companyLogo ? companyLogo : require('../avatar.png')} alt="avatar" className="w-[150px] "/>
                </div>
            }
            <div className='flex-1'>
              <Menu className='mt-4 text-sm mb-36'>
                <MenuItem icon={<HomeIcon className='w-6 h-6 text-[#0070f0]'/>} onClick={() => props.setComponent('addMedia')}> Tableau de bord </MenuItem>
                <MenuItem icon={<UserIcon className='w-6 h-6 text-[#0070f0]'/>} onClick={() => props.setComponent('profile')}> Mon profil </MenuItem>
                <MenuItem icon={<Cog6ToothIcon className='w-6 h-6 text-[#0070f0]'/>} onClick={() => props.setComponent('settings')}> Paramètres </MenuItem>
                <MenuItem icon={<QuestionMarkCircleIcon className='w-6 h-6 text-[#0070f0]'/>} onClick={() => props.setComponent('apropos')}> À propos </MenuItem>
                <MenuItem className="hover:text-red-500" style={{backgroundColor: backgroundColor}} onMouseEnter={hoverLogout} onMouseLeave={hoverLogout} icon={<ArrowLeftStartOnRectangleIcon className='w-6 h-6 text-[#0070f0]'/>} onClick={logout}> Déconnexion </MenuItem>
              </Menu>
              <MessageInformations isOpen={isOpen} onOpenChange={onOpenChange} content={errorMessage} placement="bottom-center" type="info"/>
              <div className='flex flex-col items-center justify-center mb-4'>
              
              <Button isIconOnly={!props.trigger} startContent={<InformationCircleIcon className="w-6 h-6"/>} className="bg-gray-200 shadow-none transparent border-1" onClick={
                () => {
                  onOpen();
                  setErrorMessage({
                    content:
                    <div className="flex flex-col space-y-2">
                      <span >Contactez-nous par mail: <a className="font-semibold" href="mailto:info@evoscreen.ch">info@evoscreen.ch</a></span>
                      <span >Support TeamViewer: <Link href="https://download.teamviewer.com/QS">Télécharger TeamViewer</Link></span>
                    </div>,
                    size: "xl",
                    title: "Support"
                  });
                }
              
              }>
                {props.trigger ? "Besoin d'aide ?" : null}
              </Button>
              </div>
              { !props.trigger ?
                null
                : <div className='flex flex-col items-center text-justify'>
                <span className="text-sm">Copyright 2024 </span>
                <Link isExternal className="text-sm text-black" href={"//www.evoscreen.ch"}> Evo One SA</Link>
                <span className="text-sm"> Tous droits réservés</span>
              </div>
              }
            </div>
            
          </div>
          {/* Add copyright */}
          {/*
          props.trigger ?
            <div className='flex items-center justify-center'>
              <img src={require('../navette.png')} className='flex items-center justify-center w-12 h-12 mt-12'/>
            </div>
            : <div className='flex flex-col items-center'>
            <img src={require('../navette.png')} className='w-24 h-24 m-8'/>
            <Button endContent className='w-2/3 bg-[#ffbd03] font-bold italic'>Upgrade</Button>
            </div> 
          */}
          
          
        </Sidebar>
    );
}

export default SideLeftMenu;