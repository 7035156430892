import React, { useEffect, useRef, useState } from "react";
import {Navbar, NavbarContent, DropdownItem, DropdownTrigger, Dropdown, DropdownMenu, Avatar, Badge, Button, useDisclosure, Link, Image, Tooltip} from "@nextui-org/react";
import { CalendarDaysIcon, ComputerDesktopIcon} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BellAlertIcon, ChevronLeftIcon, ChevronRightIcon, QuestionMarkCircleIcon} from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { frCH } from "date-fns/locale";
import MessageInformations from "./Utils/MessageInformations";
import ScreenDisplay from "./ScreenDisplay";
import locale from "antd/es/date-picker/locale/en_US";
import { confirmAlert } from "react-confirm-alert";
import { Alert } from "antd";


function NavbarFlow(props) {
    const navigate = useNavigate();
    const [logged, setLogged] = useState(true);
    const userData = JSON.parse(localStorage.getItem('profile') || '{}');
    const clientConfig = React.useMemo(() => {
      const clientData = localStorage.getItem('client') || '{}';
      return JSON.parse(clientData);
  }, [localStorage.getItem('client')]);
    const [screens, setScreens] = React.useState();
    const [time, setTime] = useState(format(new Date(), 'dd MMMM yyyy - HH:mm:ss', {locale: frCH}));
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [errorMessage, setErrorMessage] = useState("");
    const disabledKeys = useRef([]);

    useEffect(() => {
      // Définit un intervalle qui s'exécute toutes les secondes
      const interval = setInterval(() => {
        setTime(format(new Date(), 'dd MMMM yyyy - HH:mm:ss', {locale: frCH})); // Met à jour avec l'heure actuelle
      }, 1000);
  
      // Fonction de nettoyage pour arrêter l'intervalle
      // quand le composant se démonte
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL_API + "/screen", {
          withCredentials: true,
        }).then((response) => {
          setScreens(response.data);
          disabledKeys.current = Array.from(response.data).filter((screen) => screen.active === false).map((screen) => screen.id.toString());
        }).catch((error) => {
        });
      }, []);

    const logout = () => {
      confirmAlert({
        message: 'Voulez-vous vraiment vous déconnecter ?',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
              axios.post(process.env.REACT_APP_BASE_URL_API + "/users/logout", {}, { withCredentials: true }).then((response) => {
                setLogged(false);
                localStorage.clear();
              }).catch(() => {
                  confirmAlert({
                    title: 'Erreur de déconnexion',
                    message: 'Veuillez réessayer.',
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => {}
                      }
                    ]
                  });
              });
            }
          },
          {
            label: 'Non',
            onClick: () => {}
          }
        ]})
    }

    useEffect(() => {
        if (logged) return;
        props.setLogged(false);
        navigate("/");
    }
    , [logged]);


  return (
    <div className="flex flex-col">
    <div className="sticky flex flex-row items-center justify-center w-full">
      <Button  isIconOnly onClick={() => {props.setMenuTrigger(!props.trigger)}} className='relative bg-[#fbfbfb] -left-1 border-1 border-l-0 rounded-none rounded-tr-lg rounded-br-lg shadow-none w-fit'>
      { props.trigger ? <ChevronLeftIcon color="black" className="w-8 h-8"/> : <ChevronRightIcon color="black" className="w-8 h-8"/>}</Button>
      <span className="w-64 text-xs text-gray-600 dark:text-gray-400">{props.trigger ? "Réduire le menu" : "Élargir le menu"}</span>
    <Navbar position="sticky" className="justify-end w-full p-4 ">
      {/* Affiche un bouton lorsque l'ecran est petit*/}
      <NavbarContent as="div" justify="end" className="justify-end w-full">
        <div className="flex flex-row p-2 bg-transparent rounded-lg w-fit border-1">
          <CalendarDaysIcon className="w-6 h-6 mr-2" color='black'/>
          {time}
        </div>
        <MessageInformations isOpen={isOpen} onOpenChange={onOpenChange} content={errorMessage}/>
        <Dropdown placement="bottom-end" backdrop="blur">
            <DropdownTrigger>
              <Button startContent={<ComputerDesktopIcon className="w-6 h-6"/>} className="bg-transparent shadow-none border-1">
                Liste des écrans <span className="w-6 text-white bg-blue-500 rounded-full ">{screens && Array.from(screens).length > 0 ? Array.from(screens).length : 0}</span>
              </Button>
            </DropdownTrigger>
            
        <DropdownMenu emptyContent={"Vous ne disposez pour le moment d'aucun système d'affichage."} disabledKeys={disabledKeys.current} aria-label="Profile Actions" variant="flat">
          {screens &&
            screens.map((screen, idx) => (
              <DropdownItem textValue={screen.name} className="flex flex-row" key={screen.id} showDivider={screens.length > 1 && idx + 1 != screens.length  ? true : false}>
                <div className="flex flex-row items-center justify-between w-full">
                  {/* Bouton pour redémarrer une instance */}
                  

                  {/* Titre cliquable pour ouvrir une fenêtre */}
                  <div className={`w-6 h-6 mr-1 rounded-full ${screen.active ? 'bg-green-500' : 'bg-red-500'}`}></div>
                  <div className="flex-1 cursor-pointer" onClick={() => {
                    setErrorMessage({
                      content: <ScreenDisplay screen={screen} setComponent={props.setComponent}/>,
                      size: '4xl',
                    });
                    onOpen();
                  }}>
                    {screen.name}
                  </div>

                  {/* Indicateur de statut */}
                  <Tooltip content={"Redémarrer l'écran"} placement="top">
                  <Button isIconOnly className="mr-2 bg-transparent" onPress={() => 
                    confirmAlert({
                      message: 'Voulez-vous redémarrer l\'écran ' + screen.name + ' ?',
                      buttons: [
                        {
                          label: 'Oui',
                          onClick: () => {
                            axios.post(process.env.REACT_APP_BASE_URL_API + "/screen/restart", {screen_id: screen.id}, {
                              withCredentials: true,
                            }).then((response) => {
                              confirmAlert({
                                title: 'Succès',
                                message: 'L\'écran ' + screen.name + ' a été redémarré avec succès.',
                                buttons: [
                                  {
                                    label: 'Ok',
                                    onClick: () => {}
                                  }
                                ]
                              });
                            }).catch((error) => {
                              confirmAlert({
                                message: 'Une erreur est survenue lors du redémarrage de l\'écran ' + screen.name,
                                buttons: [
                                  {
                                    label: 'Ok',
                                    onClick: () => {}
                                  }
                                ]
                              });
                            });
                          }
                        },
                        {
                          label: 'Non',
                          onClick: () => {}
                        }
                      ]
                    })
                    
                  }>
                    <img className="w-6 bg-transparent" src={require('./../assets/restart.png')} alt="Restart"></img>
                  </Button>
                  </Tooltip>
                </div>
              </DropdownItem>
            ))
          }
        </DropdownMenu>
          
        </Dropdown>
        {/*
          <Dropdown placement="bottom-end">
          <DropdownTrigger>
              <Button startContent={<BellAlertIcon className="w-6 h-6"/>} className="bg-transparent shadow-none border-1">
                Notifications <span className="w-6 text-white bg-red-500 rounded-full ">7</span>
              </Button>
            </DropdownTrigger>
            
            <DropdownMenu emptyContent={"Aucune notifications"} aria-label="Profile Actions" variant="flat">
            { notifications &&
              notifications.map((notif) => {
                return <DropdownItem key={notif.id} showDivider={notif.lenght > 1 ? true : false} onClick={() => props.setComponent('screen')}>{notif.info}</DropdownItem>
              })
            }
          </DropdownMenu>
        </Dropdown>
      */}
        {/*
          <Button className="mr-4 bg-transparent border-none shadow-none">
            <Badge color="danger" content={5}  shape="circle">
                <NotificationIcon className="fill-content1" />
            </Badge>
          </Button>
        */}
        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <div className="flex flex-row items-center justify-center space-x-2 cursor-pointer">
              <Avatar
                isBordered
                as="button"
                className="transition-transform bg-white"
                color="white"
                size="lg"
                src={userData ? userData.avatar : require('../avatar.png')}
              />
              <div className="hidden md:block">
                <span className="text-gray-600 dark:text-gray-400">Hello, </span>
                <span className="font-semibold">{userData ? userData.firstname: '-'} {userData ? userData.lastname: '-'}</span>
              </div>
            </div>
          </DropdownTrigger>
          <DropdownMenu aria-label="Profile Actions" variant="flat">
            <DropdownItem key="profile-data">
              <p className="font-semibold">{userData ? userData.company: '-'}</p>
            </DropdownItem>
            <DropdownItem key="dashboard" onClick={() => props.setComponent('addMedia')}>Tableau de bord</DropdownItem>
            <DropdownItem key="profile" onClick={() => props.setComponent('profile')}>Mon profil</DropdownItem>
            <DropdownItem key="settings" onClick={() => props.setComponent('settings')}>Paramètres</DropdownItem>
            <DropdownItem key="apropos" onClick={() => props.setComponent('apropos')}>À propos</DropdownItem>
            <DropdownItem className="cursor-auto hover:none blur-none" key="divider"><hr/></DropdownItem>
            <DropdownItem key="logout" color="danger" onClick={logout}>
              Déconnexion
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavbarContent>
    </Navbar>
    </div>
    { props.component == 'addMedia' ?
      <div className='flex flex-row items-end justify-end w-full h-80'>
        <div className="flex items-center justify-center w-full h-full ml-8 mr-8 rounded-badge">
          <Image src={clientConfig.banniere} alt="companyLogo" className='items-center justify-center object-cover w-[100%] h-[100%] rounded-xl'/>
        </div>
      </div> : null
    }
    </div>
  );
}

export default NavbarFlow;